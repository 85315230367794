import * as React from "react";

import { WorkspaceSidebarContext } from "./context";
import { SidebarTab, SidebarView, SidebarViewType } from "./sidebarView";

export function WorkspaceSidebarProvider({
  children,
}: React.PropsWithChildren) {
  const [activeView, setActiveView] = React.useState<SidebarView<unknown>>({
    type: SidebarViewType.TabbedSidebar,
    data: undefined,
  });
  const [activeDrawer, setActiveDrawer] =
    React.useState<SidebarView<unknown> | null>(null);

  const [activeTab, setActiveTab] = React.useState<SidebarTab>(
    SidebarTab.Topics,
  );

  const close = () => {
    setActiveView({
      type: SidebarViewType.None,
      data: undefined,
    });
  };

  return (
    <WorkspaceSidebarContext.Provider
      value={{
        activeView,
        activeDrawer,
        close,
        openView: setActiveView,
        toggleDrawer: setActiveDrawer,
        activeTab: activeTab,
        setActiveTab: setActiveTab,
      }}
    >
      {children}
    </WorkspaceSidebarContext.Provider>
  );
}
