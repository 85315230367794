import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { ExpandableCopyText, PageHeader } from "@/components";
import { TagsGroup, Tags } from "@/components/tags";
import { useNavigation } from "@/providers";
import { Dataset } from "@/types";

import { EditableDatasetDescription } from "./EditableDatasetDescription";
import { EditableDatasetName } from "./EditableDatasetName";

interface DatasetHeaderProps {
  datasetId: string;
  dataset: Dataset | undefined;
  showTags?: boolean;
}

export const DatasetHeader: React.FC<DatasetHeaderProps> = ({
  datasetId,
  dataset,
  showTags,
}) => {
  const theme = useTheme();
  const { goto } = useNavigation();

  const goToDatasetsByTag = (tag: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set("query", tag);
    goto.search(queryParams);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: theme.spacing(2), minWidth: "200px" }}>
          <PageHeader>
            <ExpandableCopyText
              title="Dataset"
              text={datasetId}
              trimLength={15}
            />
          </PageHeader>
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
            minHeight: "32px",
          }}
        >
          <Box
            sx={{
              opacity: showTags ? 1 : 0,
              transition: "opacity 0.2s",
            }}
          >
            <TagsGroup>
              <Tags tags={dataset?.tags || []} onClickTag={goToDatasetsByTag} />
            </TagsGroup>
          </Box>
        </Box>
      </Box>

      <EditableDatasetName datasetId={datasetId} name={dataset?.name} />

      <Box sx={{ mt: theme.spacing(1.5) }}>
        <EditableDatasetDescription
          datasetId={datasetId}
          description={dataset?.description}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
          mt: theme.spacing(2),
        }}
      >
        {dataset && dataset.tags && showTags && (
          <TagsGroup>
            <Tags tags={dataset.tags} onClickTag={goToDatasetsByTag} />
          </TagsGroup>
        )}
      </Box>
    </>
  );
};
