import chroma from "chroma-js";
import ColorHash from "color-hash";

import { LoggerService } from "@/service";
import type { PlotSeries } from "@/state/visualization";

const colorHash = new ColorHash({ lightness: 0.5, saturation: 0.5 });

export function colorForSeries(series: PlotSeries): string {
  const userSelectedColor = (series?.style ?? {}).lineColor as
    | string
    | undefined;
  return userSelectedColor ?? colorHash.hex(series.id);
}

export function colorForAnnotation(
  annotationName: string,
  opacity: number = 0.2,
  requestedColor?: string,
): string {
  // Respect any user-requested colors
  if (requestedColor) {
    return getRGBA(requestedColor, opacity);
  }

  // Otherwise generate a color by hashing the name
  const rgb = colorHash.rgb(annotationName);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity.toFixed(2)})`;
}

/**
 * Converts any valid CSS color into an RGBA string with the specified opacity.
 *
 * This function supports all valid CSS color formats, such as:
 * - Named colors (e.g., "red", "blue", "green")
 * - Hexadecimal colors (e.g., "#ff0000", "#00ff00", "#0000ff")
 * - RGB/RGBA strings (e.g., "rgb(255, 0, 0)", "rgba(255, 0, 0, 1)")
 * - HSL/HSLA strings (e.g., "hsl(0, 100%, 50%)", "hsla(0, 100%, 50%, 0.5)")
 *
 * The function validates the input color using Chroma.js.
 * If the color is invalid, it returns a fallback color.
 *
 * @param {string} color - A string representing any valid CSS color.
 * @param {number} opacity - A number between 0 and 1 representing the desired alpha channel value.
 * @returns {string} An RGBA string (e.g., "rgba(255, 0, 0, 0.5)") representing the color with the specified opacity.
 *
 * @example
 * getRGBA("red", 0.5); // "rgba(255, 0, 0, 0.50)"
 * getRGBA("#00ff00", 0.8); // "rgba(0, 255, 0, 0.80)"
 * getRGBA("rgb(0 0 255)", 0.3); // "rgba(0, 0, 255, 0.30)"
 */
function getRGBA(color: string, opacity: number): string {
  try {
    return chroma(color).alpha(opacity).css();
  } catch (e) {
    LoggerService.warn(`Invalid color: ${color}`, e);
    return `rgba(100, 100, 100, ${opacity.toFixed(2)})`; // fallback color
  }
}
