import * as React from "react";

import { SidebarTab, SidebarView, SidebarViewType } from "./sidebarView";

const noop = () => {};

export interface WorkspaceSidebarContext {
  activeView: SidebarView<unknown>;
  activeDrawer: SidebarView<unknown> | null;
  close: () => void;
  openView: <T>(view: SidebarView<T>) => void;
  toggleDrawer: <T>(view: SidebarView<T> | null) => void;
  activeTab: SidebarTab;
  setActiveTab: (tab: SidebarTab) => void;
}

export const WorkspaceSidebarContext =
  React.createContext<WorkspaceSidebarContext>({
    activeView: {
      type: SidebarViewType.TabbedSidebar,
      data: undefined,
    },
    activeDrawer: null,
    close: noop,
    openView: noop,
    toggleDrawer: noop,
    activeTab: SidebarTab.Topics,
    setActiveTab: noop,
  });

export function useWorkspaceSidebar(): WorkspaceSidebarContext {
  return React.useContext(WorkspaceSidebarContext);
}
